import classnames from 'classnames';

import React, { Component } from 'react';
import styles from './TopBar.module.css';
import menuImage from './images/menu.svg';
import closeImage from './images/x.svg';

class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolled: false,
            menuOpen: false
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    handleScroll() {
        if (window.scrollY > 200) {
            if (this.state.scrolled === false) {
                this.setState({scrolled: true});
            }
        } else {
            if (this.state.scrolled === true) {
                this.setState({scrolled: false});
            }
        }
    }

    openMenu() {
        this.setState({menuOpen: true});
    }

    closeMenu() {
        this.setState({menuOpen: false});
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        const topBarClasses = classnames(styles.topbar, {
            [styles.scrolled]: this.state.scrolled,
            [styles.menuOpen]: this.state.menuOpen
        });

        return (
            <div className={topBarClasses}>
                <div className={styles.topbarContent}>
                    <h1><a className={styles.header} href="#headshot">Bryan Jadot</a></h1>
                    <button className={styles.menuButton} onClick={this.openMenu}>
                        <img src={menuImage} alt="Open navigation menu" />
                    </button>
                    <div className={styles.menu}>
                        <div className={styles.closeMenuWrapper}>
                            <button className={styles.closeMenuButton} onClick={this.closeMenu}>
                                <img className={styles.closeMenuImage} src={closeImage} alt="Close navigation menu" />
                            </button>
                        </div>
                        <nav>
                            <ul className={styles.linkList}>
                                <li className={styles.listItem}><a className={styles.link} href="#headshot">Home</a></li>
                                <li className={styles.listItem}><a className={styles.link} href="#experience">Experience</a></li>
                                <li className={styles.listItem}><a className={styles.link} href="#projects">Projects</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

        );
    }
}

export default TopBar;
