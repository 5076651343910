import classnames from 'classnames';
import React, { Component } from 'react';
import styles from './Components.module.css';

export class SectionHeader extends Component {
    render() {
        return (
            <header>
                <h2 className={classnames(this.props.className, styles.header)}>
                    {this.props.children}
                </h2>
            </header>
        )
    }
}

export class SectionSubheader extends Component {
    render() {
        return (
            <h3 className={classnames(this.props.className, styles.subheader)}>
                {this.props.children}
            </h3>
        )
    }
}
