import MainPage from './MainPage';

import React from 'react';

import styles from './App.module.css'


function App() {

    return (
        <div className={styles.app}>
            <MainPage />
        </div>
    )
}

export default App;
