import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA from "react-ga";

ReactGA.initialize("UA-29153738-3");

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<App />, rootElement);
} else {
    ReactDOM.render(<App />, rootElement);
}
