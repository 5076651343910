import React, { Component } from 'react';
import {SectionHeader} from './Components';
import {SectionGrid, GridItem} from './SectionGrid';

import styles from './ProjectsPage.module.css';

class A extends Component {
    render() {
        return (
            <a className={styles.listItemLink} href={this.props.href} rel="noopener noreferrer" target="_blank">{this.props.children}</a>
        );
    }
}

class ProjectsPage extends Component {
    render() {
        const ticTacToe = (
            <React.Fragment>
                <ul className={styles.list}>
                    <li className={styles.listItem}>
                        Game (<A href="https://ttt.bryanjadot.com/">link</A>)
                    </li>
                    <li className={styles.listItem}>
                        Source (<A href="https://gitlab.com/bryan.jadot/tictactoe">link</A>)
                    </li>
                    <li className={styles.listItem}>
                        A little tic tac toe game I made to practice the latest and greatest of ReactJS and redux.
                    </li>
                    <li className={styles.listItem}>
                        Served from S3 with Cloudflare as CDN. I've found better load times with Cloudfront in my tests, but Cloudflare has a better cost model and more features.
                    </li>
                    <li className={styles.listItem}>
                        Planning to make it multiplayer to get to know Amazon Lambda and DynamoDB.
                    </li>
                </ul>
            </React.Fragment>
        );
        const speedMiner = (
            <React.Fragment>
                <ul className={styles.list}>
                    <li className={styles.listItem}>
                        Source (<A href="https://github.com/BryanJadot/speed_miner">link</A>)
                    </li>
                    <li className={styles.listItem}>
                        At the peak of the crypto bubble in early 2018, I built this miner to mine multiple coins based on their profitability.
                    </li>
                    <li className={styles.listItem}>
                        Built for GPU miners, not ASICs so may not be profitable anymore depending on your utility costs.
                    </li>
                    <li className={styles.listItem}>
                        Users can configure this to mine with Nicehash, zPool or add their own pool.
                    </li>
                    <li className={styles.listItem}>
                        Runs benchmarks on your machine to determine which miner will be fastest to use per coin/algorithm.
                    </li>
                </ul>
            </React.Fragment>
        );
        const website = (
            <React.Fragment>
                <ul className={styles.list}>
                    <li className={styles.listItem}>
                        Website (<A href="https://bryanjadot.com">link</A>)
                    </li>
                    <li className={styles.listItem}>
                        Source (<A href="https://gitlab.com/bryan.jadot/bryan-website">link</A>)
                    </li>
                    <li className={styles.listItem}>
                        This is the source code for this website!
                    </li>
                    <li className={styles.listItem}>
                        Working on writing more content to outline my skills and current projects.  Also, working on SEO in general as the website is ranked very low.
                    </li>
                    <li className={styles.listItem}>
                        Planning to explore a static site generator, maybe Gatsby. Right now, I use React Snap to generate a static site on build.
                    </li>
                </ul>
            </React.Fragment>
        );
        const coduru = (
            <React.Fragment>
                <ul className={styles.list}>
                    <li className={styles.listItem}>
                        Source (<A href="https://bitbucket.org/BryanJadot/interviewtool">link</A>)
                    </li>
                    <li className={styles.listItem}>
                        In college, some friends and I started a little company to help companies do a better job interviewing engineers.
                    </li>
                    <li className={styles.listItem}>
                        We built a product that allows candidates to code collaboratively in the browser with their interviewer, run/test their code and video/text chat while they do it.
                    </li>
                    <li className={styles.listItem}>
                        Took our project through Steve Blank's Lean Launchpad program, developing our business plan and pivoting several times.
                        Deck (<A href="https://www.slideshare.net/sblank/kevin-bryan-coduru">link</A>)
                    </li>
                    <li className={styles.listItem}>
                        Decided not to pursue the start up post college because we didn't find a business model that would generate us sizable revenue.
                    </li>
                </ul>
            </React.Fragment>
        );
        return (
            <div>
                <SectionHeader className={styles.header}>My Projects.</SectionHeader>
                <SectionGrid size="400px">
                    <GridItem header="Tic Tac Toe">{ticTacToe}</GridItem>
                    <GridItem header="Speed Crypto Miner">{speedMiner}</GridItem>
                    <GridItem header="My Website">{website}</GridItem>
                    <GridItem header="Coduru">{coduru}</GridItem>
                </SectionGrid>
            </div>
        );
    }
}

export default ProjectsPage;