import caltech from './images/caltech.webp';
import caltechPng from './images/caltech.png';
import dropbox from './images/dropbox.svg';
import dropboxPng from './images/dropbox.png';
import facebook from './images/facebook.svg';
import facebookPng from './images/facebook.png';

import React, { Component } from 'react';
import {SectionHeader} from './Components';
import {SectionGrid, GridItem} from './SectionGrid';

import styles from './ExperiencePage.module.css';

class ExperiencePage extends Component {
    render() {
        const experiencesToList = (exps) => {
            const expList = exps.map((e, i) => <li className={styles.detail} key={i}>{e}</li>);
            return <ul className={styles.detailsList}>{expList}</ul>
        };
        const dropboxInfo = {
            img: [dropbox, dropboxPng],
            header: "Dropbox (2012 \u2013 2019)",
            experiences: experiencesToList([
                "Engineering manager for a full-stack team (4 FE, 5 BE, 1 ML) overseeing the surfaces users first see when they interact with Dropbox on Desktop and Web. This team also builds our new Personalization experiences that leverages ML to help users get back to their files and other content faster.",
                "Developed \"Content Suggestions\" on web that increased opens by 30% on the logged-in homepage and increased website-wide retention by 2%.",
                "Developed \"Content Suggestions\" on desktop that are driving 100k MAU.",
                "Rebuilt the Desktop Tray from scratch, bringing personalized experiences that are driving 100k MAU. Also, redesigned the overall experience to improve usability and understandability",
                "Reduced TTI (p75) of the Dropbox logged-in homepage from >6s to 2.7s."
            ])
        };
        const facebookInfo = {
            img: [facebook, facebookPng],
            header: "Facebook (2011)",
            experiences: experiencesToList([
                "Software Engineering Intern",
                "Worked on a number of projects aimed at building out the Facebook Credits' front-end. Facebook Credits are a discontinued virtual currency, designed by Facebook for usage in their stores."
            ])
        };
        const caltechInfo = {
            img: [caltech, caltechPng],
            header: "Caltech (2009 \u2013 2013)",
            experiences: experiencesToList([
                "Graduated with Honors with a B.S. in Computer Science.",
                "Helped lead Dropbox's entrepreneurship club, organizing trips to meet with Bay Area tech companies and entrepreneurs.",
                "Teaching Assistant for many classes, including C, C++, Python and Algorithms."
            ])
        };

        return (
            <div>
                <SectionHeader className={styles.sectionHeader}>My Experience.</SectionHeader>
                <SectionGrid size="420px">
                    <GridItem img={dropboxInfo.img} header={dropboxInfo.header}>
                        {dropboxInfo.experiences}
                    </GridItem>
                    <GridItem img={facebookInfo.img} header={facebookInfo.header}>
                        {facebookInfo.experiences}
                    </GridItem>
                    <GridItem img={caltechInfo.img} header={caltechInfo.header}>
                        {caltechInfo.experiences}
                    </GridItem>
                </SectionGrid>
            </div>
        )
    }
}

export default ExperiencePage;