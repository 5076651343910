import React, {Component} from 'react';
import headshot from './images/headshot.webp';
import headshotJpg from './images/headshot.jpg';
import linkedin from './images/linkedin.svg';
import email from './images/email.svg';
import link from './images/link.svg';
import {SectionHeader} from './Components';
import Img from 'react-image';

import styles from './HeadShotPage.module.css';

class HeadShotPage extends Component {
    render() {
        return (
            <div className={styles.headShotPageWrapper}>
                <div className={styles.headShotHolder}>
                    <Img className={styles.headShot} src={[headshot, headshotJpg]} alt="Bryan Jadot's headshot" />
                </div>
                <div className= {styles.infoHolder}>
                    <SectionHeader className={styles.name}>
                        I'm Bryan.
                    </SectionHeader>
                    <p className={styles.description}>
                        Full-stack engineering leader.
                    </p>
                    <address className={styles.address}>
                        <ul className={styles.contactInfos}>
                            <li>
                                <a className={styles.contact} href="https://www.linkedin.com/in/bryan-jadot-00063426/">
                                    <div className={styles.contactImageHolder}>
                                        <img className={styles.contactImage} src={linkedin} alt="Contact me on LinkedIn" />
                                    </div>
                                    <div className={styles.contactText}>LinkedIn</div>
                                </a>
                            </li>
                            <li>
                                <a className={styles.contact} href="mailto:bryanjspam@gmail.com">
                                    <div className={styles.contactImageHolder}>
                                        <img className={styles.contactImage} src={email} alt="Contact me by email" />
                                    </div>
                                    <div className={styles.contactText}>Email</div>
                                </a>
                            </li>
                            <li>
                                <a className={styles.contact} href="/resume.pdf">
                                    <div className={styles.contactImageHolder}>
                                        <img className={styles.contactImage} src={link} alt="My resume" />
                                    </div>
                                    <div className={styles.contactText}>Resume</div>
                                </a>
                            </li>
                        </ul>
                    </address>
                </div>
            </div>
        );
    }
}

export default HeadShotPage;