import classnames from 'classnames';
import Img from 'react-image'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './SectionGrid.module.css';
import {SectionSubheader} from './Components';

export class GridItem extends Component {
    constructor(props) {
        super(props);
        this.state = {showMoreInfo: false};
    }

    render() {
        const toggleContent = () => this.setState({showMoreInfo: !this.state.showMoreInfo});
        const moreInfoClasses = classnames(
            styles.moreInfo,
            {[styles.show]: this.state.showMoreInfo}
        );

        return (
            <div onClick={toggleContent} className={styles.gridItemInner}>
                <div className={styles.imgHolder}>
                    {!Object.is(this.props.img, undefined) &&
                        <Img className={styles.img} src={this.props.img} alt={this.props.header} />
                    }
                    <SectionSubheader className={styles.header}>{this.props.header}</SectionSubheader>
                </div>
                {!Object.is(this.props.children, undefined) &&
                    <div className={moreInfoClasses}>
                        {this.props.children}
                    </div>
                }
            </div>
        );
    }
}

GridItem.propTypes = {
    img: PropTypes.arrayOf(PropTypes.string),
    header: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}

export class SectionGrid extends Component {
    render() {
        const style = {width:this.props.size, height: this.props.size};
        const children = this.props.children.map(
            (e, i) => <li className={styles.gridItem} style={style} key={i}>{e}</li>
        );
        return <ul className={styles.grid}>{children}</ul>;
    }
}

SectionGrid.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    size: PropTypes.string.isRequired
}
