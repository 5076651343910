import classnames from 'classnames';
import ExperiencePage from './ExperiencePage';
import HeadShotPage from './HeadShotPage';
import ProjectsPage from './ProjectsPage';
import TopBar from './TopBar';
import React, { Component } from 'react';
import star from './images/star.svg';

import styles from './index.module.css';

class Section extends Component {
    render() {
        return (
            <section id={this.props.id} className={styles.section}>
                {this.props.children}
            </section>
        )
    }
}

class MainPage extends Component {
    constructor (props) {
        super(props);

        this.handleTimeOfDay = this.handleTimeOfDay.bind(this);
        this.state = {timeOfDay: 0};
        this.contentRef = React.createRef();
        this.stars = this.generateRandomStars(20);
    }

    handleTimeOfDay() {
        const currentPage = this.getCurrentPage();
        if (this.state.timeOfDay !== currentPage) {
            this.setState({timeOfDay: currentPage});
        }
    }

    getCurrentPage() {
        if (Object.is(this.contentRef.current, undefined)) {
            return 0;
        }

        const scroll = window.scrollY + 0.4 * window.innerHeight;
        const children = Array.from(this.contentRef.current.children);

        return children.findIndex((e) => {return e.offsetTop < scroll && e.offsetTop + e.clientHeight > scroll}) % children.length;
    }

    componentDidMount () {
        window.addEventListener("scroll", this.handleTimeOfDay);
    }

    componentWillUnmount () {
        window.removeEventListener("scroll", this.handleTimeOfDay);
    }

    generateRandomStars(numStars) {
        const stars = [];

        for (let i = 0; i < numStars; i++) {
            const size = Math.trunc(Math.random() * 10 + 30);
            const style = {
                top: `${Math.trunc(100 * Math.random())}%`,
                left: `${Math.trunc(100 * Math.random())}%`,
                width: size,
                height: size
            };
            stars.push(<img alt="star" src={star} className={styles.star} key={i} style={style} />);
        }

        return stars;
    }

    render() {
        const bgColorClass = classnames(styles.backgroundColor, styles[`color${this.state.timeOfDay}`]);
        const starClasses = classnames(styles.stars,
            {[styles.show]: this.state.timeOfDay === 2}
        );
        return (
            <React.Fragment>
                <header>
                    <TopBar />
                    <div data-nosnippet="">
                        <div className={bgColorClass} />
                        <div className={starClasses}>
                            {this.stars}
                        </div>
                        <div className={styles.background} />
                    </div>
                </header>
                <main className={styles.content} ref={this.contentRef}>
                    <Section id="headshot"><HeadShotPage /></Section>
                    <Section id="experience"><ExperiencePage /></Section>
                    <Section id="projects"><ProjectsPage /></Section>
                </main>
            </React.Fragment>
        );
    }
}

export default MainPage;